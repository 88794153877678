<template>
  <div class="order-list">
    <app-list :opt="opt" @get="onGet"></app-list>
    <el-dialog title="退款失败" :visible.sync="dialogVisible" width="520px" :close-on-click-modal="false">
      <div class="alertTilte">
        <div class="ul_div">
          <div class="ul_li_one">退款总金额：</div>
          <div class="ul_li_thrwe">
            <span v-text="dialogList.refundFee"></span>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">退款审核时间：</div>
          <div class="ul_li_thrwe">
            <span v-text="dialogList.approveTime"></span>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">退款状态：</div>
          <div class="ul_li_thrwe">
            <span v-text="dialogList.state"></span>
          </div>
        </div>
        <div class="ul_div">
          <div class="ul_li_one">扣退款失败原因：</div>
          <div class="ul_li_thrwe">
            <textarea class="textarea" v-model="dialogList.textarea" name id :disabled="true" cols="30"
              rows="10"></textarea>
          </div>
        </div>
        <div style="text-align: center;">
          <el-button @click="Oncancel()">取消</el-button>
          <el-button type="primary" @click="Re_eviewRefund()">重新审核退款</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  let validRoomNo = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
      let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
      if (!reg.test(value)) {
        callback(new Error("房号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  let validMobile = (rule, value, callback) => {
  if (value == "" || value == undefined) {
    callback();
  } else {
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(value)) {
      callback(new Error("手机号格式不对"));
    } else {
      callback();
    }
  }
};
  export default {
    data() {
      let _this = this;
      return {
        dialogVisible: false,
        dialogList: {
          refundFee: "",
          approveTime: "",
          state: "",
          textarea: "这里显示退款失败微信反馈的原因分析。"
        },
        failureList: {},
        opt: {
          title: "退款列表",
          search: [{
              key: "bizType",
              label: "租赁模式",
              type: "select-no-filterable",
              value: "0",
              opt: {
                list: [{
                    label: "长租",
                    value: "0"
                  },
                  {
                    label: "短租",
                    value: "1"
                  },
                  {
                    label: "钟点房",
                    value: "3"
                  }
                ],
                change(val) {
                  let flag = true;
                  let Flag = true;
                  let a = true;
                  _this.opt.columns.forEach((item, index) => {
                    if (item.key == "checkoutPropertyFee") {
                      if (val == '1' || val == '3') {
                        _this.opt.columns.splice(index, 1);
                        flag = false;
                      }
                    }
                  })
                  if (flag && val == '0') {
                    let obj = {
                      label: "物业费应退",
                      key: "checkoutPropertyFee",
                      width: 120
                    };
                    _this.opt.columns.splice(8, 0, obj);
                   }
                  _this.opt.columns.forEach((item, index) => {
                    if (item.key == "quitDate") {
                      if ( val == '0' || val == '1') {
                        _this.opt.columns.splice(index, 1);
                        Flag = false;
                      }
                    }
                  })
                  if (Flag && val == '3') {
                    let obj = {
                      label: "退房时间",
                      key: "quitDate",
                      width: 120
                    };
                    _this.opt.columns.splice(10, 0, obj);
                   }
                  _this.opt.columns.forEach((item, index) => {
                    if (item.key == "quitDate") {
                      if ( val == '3') {
                        _this.opt.columns.splice(index, 1);
                        a = false;
                      }
                    }
                  })
                  if (a && val != '3') {
                    let obj = {
                      label: "退房日期",
                      key: "quitDate",
                      width: 120
                    };
                    _this.opt.columns.splice(10, 0, obj);
                   }
                }
              }
            },
            {
              key: "realname",
              label: "姓名",
              maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
            },
            {
              key: "phone",
              label: "手机号",
              rules: [{
                validator: validMobile,
                trigger: ['blur']
              }]
            },
            {
              key: "apartmentId",
              label: "房源",
              type: "select",
              opt: {
                list: []
              }
            },
            {
              key: "roomNo",
              label: "房号",
              maxlength: 24,
              rules: [{
                validator: validRoomNo,
                trigger: ['blur']
              }]

            },
            {
              key: "state",
              label: "退款状态",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: "全部",
                    value: null
                  },
                  {
                    label: "待退款",
                    value: "0"
                  },
                  {
                    label: "退款中",
                    value: "1"
                  },
                  {
                    label: "退款成功",
                    value: "2"
                  },
                  {
                    label: "退款异常",
                    value: "3"
                  },
                  {
                    label: "退款取消",
                    value: "4"
                  },
                  {
                    label: "退款失败",
                    value: "5"
                  }
                ]
              }
            }
          ],
          columns: [{
              label: "退款单号",
              key: "refundNo",
              on: row => {
                console.log(row);
                console.log(row.id);
                let rentalStyle;
                 if(row.rentalStyle == '长租' ){
                        rentalStyle = 0;
                }else if(row.rentalStyle == '短租'){
                        rentalStyle = 1;
                }else if(row.rentalStyle == '长短租'){
                        rentalStyle = 2;
                }else if(row.rentalStyle == '钟点房'){
                        rentalStyle = 3;
                }
                _this.$router.push(
                  "/main/Refundlist/detail/show/" +
                  row.id +
                  "?rentalStyle=" +
                  rentalStyle +
                  "&contractId=" +
                  row.bizId +
                  "&roomId=" +
                  row.roomId +
                  "&userId=" +
                  row.userId +
                  "&orderType=" +
                  rentalStyle
                );
              }
            },
            {
              label: "租赁模式",
              key: "rentalStyle",
              align: "center"
            },
            {
              label: "房源信息",
              key: "address"
            },
            {
              label: "姓名",
              key: "realname"
            },
            {
              label: "手机号",
              key: "phone",
              width: 120
            },
            {
              label: "退款总额",
              key: "refundFee"
            },
            {
              label: "租金应退",
              key: "checkoutRentalFee"
            },
            {
              label: "押金应退",
              key: "checkoutDepositFee"
            },
            {
              label: "物业费应退",
              key: "checkoutPropertyFee",
              width: 120
            },
            {
              label: "房东扣款金额",
              key: "deductionFee",
              width: 120
            },
            {
              label: "退房日期",
              key: "quitDate",
              width: 120
            },
            {
              label: "退款状态",
              key: "state",
              width: 120,
              type: "actionStatus",
              opt: {
                ativeStatus: "退款失败",
                on(row, item) {
                  _this.dialogVisible = true;
                  _this.failureList = row;
                  _this.dialogList.refundFee = row.refundFee;
                  _this.dialogList.approveTime = row.approveTime;
                  _this.dialogList.state = row.state;
                }
              }
            },
            {
              label: "到账时间",
              key: "refundDate",
              width: 170
            }
            //   {
            //     label: "操作",
            //     key: "payState",
            //     width: 120,
            //     type: "action-alt",
            //     opt: {
            //       activeText: "已审核",
            //       // activeText2: "退款审核",
            //       inactiveText: "退款审核",
            //       on(row, item) {
            //         console.log(row);
            //         let rentalStyle = row.rentalStyle == "长租" ? 0 : 1;
            //         _this.$router.push(
            //           "/main/Refundlist/Reviewrefund/show/" +
            //             row.id +
            //             "?rentalStyle=" +
            //             rentalStyle +
            //             "&bizType=" +
            //             row.bizType
            //         );
            //       }
            //     }
            //   }
          ],
          exportButtons: [{
            type: "Export",
            url: this.EXport + "/finance-service/refundOperation/export",
            listName: '退款列表'
          }],
        }
      };
    },
    created() {
      console.log("order-list created!!");

    },
    activated() {
      this.getApartmentList();
    },
    methods: {
      onGet(opt) {
        console.log(opt);
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          bizType: opt.searchForm.bizType ? opt.searchForm.bizType : "0",
          realname: opt.searchForm.realname ? opt.searchForm.realname : null,
          phone: opt.searchForm.phone ? opt.searchForm.phone : null,
          apartmentId: opt.searchForm.apartmentId ?
            opt.searchForm.apartmentId : null,
          roomNo: opt.searchForm.roomNo ? opt.searchForm.roomNo : null,
          state: opt.searchForm.state
        }; 
        this.post("finance-service/refundOperation/list", dto, {
          isUseResponse: true
        }).then(res => {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            if (item.state == 0) {
              item.payState = 0;
            } else {
              item.payState = 1;
            }
            // if(item.contractState == 5){
            //   item.payState = 3;
            // }
            item.refundDate =
              item.refundDate == null ?
              "" :
              moment(item.refundDate).format("YYYY-MM-DD HH:mm:ss");
            if(item.rentalStyle == 3){
              item.quitDate =
              item.quitDate == null ?
              "" :
              moment(item.quitDate).format("YYYY-MM-DD HH:mm:ss");
            }else{
              item.quitDate =
              item.quitDate == null ?
              "" :
              moment(item.quitDate).format("YYYY-MM-DD");
            }
            item.orderType = ["长租", "短租","长短租","钟点房"][item.orderType];
            item.state = [
              "待退款",
              "退款中",
              "退款成功",
              "退款异常", 
              "退款取消",
              "退款失败"
            ][item.state];
            item.rentalStyle = ["长租", "短租","长短租","钟点房"][item.rentalStyle];
            item.refundFee =
              item.checkoutSum == null ?
              "￥" + "0.00" :
              "￥" + parseFloat(item.checkoutSum).toFixed(2);
            item.checkoutRentalFee =
              item.checkoutRentalFee == null ?
              "￥" + "0.00" :
              "￥" + parseFloat(item.checkoutRentalFee).toFixed(2);
            item.checkoutPropertyFee =
              item.checkoutPropertyFee == null ?
              "￥" + "0.00" :
              "￥" + parseFloat(item.checkoutPropertyFee).toFixed(2);
            item.checkoutDepositFee =
              item.checkoutDepositFee == null ?
              "￥" + "0.00" :
              "￥" + parseFloat(item.checkoutDepositFee).toFixed(2);
            item.deductionFee =
              item.deductionFee == null ?
              "￥" + "0.00" :
              "￥" + parseFloat(item.deductionFee).toFixed(2);
          }
          opt.cb(res.data);
        });
      },
      //获取房源列表
      getApartmentList() {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
            null : this.cache.getLS("userInfo")["userId"]
        };
        this.post("landlord-service/apartment/getAllApartment", dto).then(res => {
          let returnData = {};
          res.forEach(data => {
            returnData[data.id] = data.name;
          });
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          this.opt.search[3]["opt"]["list"] = res;
        });
      },
      Oncancel() {
        this.dialogVisible = false;
      },
      Re_eviewRefund() {
        let rentalStyle;
        if(this.failureList.rentalStyle == '长租' ){
          rentalStyle = 0;
        }else if(this.failureList.rentalStyle == '短租'){
          rentalStyle = 1;
        }else if(this.failureList.rentalStyle == '长短租'){
          rentalStyle = 2;
        }else if(this.failureList.rentalStyle == '钟点房'){
          rentalStyle = 3;
        }
        this.$router.push(
          "/main/Refundlist/Reviewrefund/show/" +
          this.failureList.id +
          "?rentalStyle=" +
          rentalStyle +
          "&bizType=" +
          this.failureList.bizType
        );
        this.dialogVisible = false;
      }
    }
  };
</script>
<style lang="scss">
  .order-list {
    .el-dialog__header {
      text-align: center;
    }

    .alertTilte {
      .ul_div {
        display: flex;
        line-height: 40px;
        font-family: "微软雅黑";
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }

      .ul_li_one {
        width: 150px;
        height: 40px;
        text-align: right;
      }

      .ul_li_scond {
        width: 160px;
        height: 40px;
        text-align: center;
      }

      .ul_li_thrwe {
        width: 310px;
        text-align: left;

        .img_div {
          width: 70px;
          height: 70px;
          float: left;
          border: 1px solid #dcdfe6;
          margin-right: 5px;
          margin-bottom: 5px;

          img {
            width: 100%;
            height: 100%;
            vertical-align: top;
          }
        }
      }

      .textarea {
        width: 304px;
        height: 80px;
        border: none;
        outline: none;
        background-color: #ffffff;
        color: #333333;
        resize: none;
      }
    }
  }
</style>